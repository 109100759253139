import React, { Component } from "react";
import "./App.css";
import Header from "./components/Header";
import ProjectGallery from "./components/ProjectGallery";

class App extends Component {
  render() {
    return (
      <div className="App">
        <div className="portfolio-container">
          <Header />
          <ProjectGallery />
        </div>
      </div>
    );
  }
}

export default App;
