import React from "react";
//import projects from "../components/projects.json";
import projects from "../components/projects";

const ProjectGallery = () => (
  <div className="projects-container">
    <div className="projects-list">
      {projects.projects.map((project) => (
        <ProjectCard key={project.id} project={project} />
      ))}
    </div>
  </div>
);

const ProjectCard = (props) => (
  <a href={props.project.url} className="project">
    <div className="project-name">
      <div className="project-title">{props.project.title}</div>
      <div className="project-subtitle">{props.project.subtitle}</div>
    </div>
    <div className="image-container">
      <img src={props.project.image} alt={props.project.alttag}/>
    </div>
  </a>
);

export default ProjectGallery;
