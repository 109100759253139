import React from "react";
import profilePhoto from "./profilePhoto.jpg";

const Header = () => (
  <header className="App-header">
    <div className="profile-info">
      <div className="fake-cut"></div>
      <div className="fake-cut-line"></div>
      <div className="profile-picture">
        <img src={profilePhoto} alt="my smiling face" />
      </div>
      <h1>Steven Mocarski</h1>
      <h2>Experience Designer</h2>
      <ul className="profile-links">
        <li className="btn"><a href="https://uxfol.io/stevenmocarski/contact">Contact</a></li>
        <li className="btn"><a href="https://storage.googleapis.com/uxfolio/5c6b2680110bd000043dae8f/cv/Resume_Steven_Mocarski_m6e.pdf">Resume</a></li>
      </ul>
      <p className="profile-about">
        I enjoy creating digital products that help people accomplish tasks,
        achieve goals, and learn new things. My primary expertise is interaction
        design and information architecture. I also get puzzle-solving
        satisfaction creating prototypes.
      </p>
      <p className="profile-about">
        I have worked for a range of clients and companies in hospitality,
        financial services, and career recruitment.
      </p>
    </div>
  </header>
);

export default Header;
