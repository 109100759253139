module.exports = {
    "projects": [
    {
        "id": 1,
        "title": "Marriott Hotel Websites",
        "subtitle": '"Elevated Design Concepts"',
        "image": require("./images/person-jumping.jpg"),
        "alttag": "Person jumping up",
        "url": "https://uxfol.io/project/04babf8c/Marriott-Hotel-Websites",
        "tags": []
    },
    {
        "id": 2,
        "title": "Branding & Identity",
        "subtitle": "Marriott Hotel Websites",
        "image": require("./images/laptop-screen.jpg"),
        "alttag": "Open laptop on a desk",
        "url": "https://uxfol.io/project/043dae91/Branding---Identity",
        "tags": []
    },
    {
        "id": 3,
        "title": "RAM Platform Migration",
        "subtitle": "Re-Imagine & Accelerate Marriott Digital",
        "image": require("./images/tunnel-acceleration.jpg"),
        "alttag": "Accelerating through a tunnel",
        "url": "https://uxfol.io/project/0482d8b7/RAM-Platform-Migration",
        "tags": []
    },
    {
        "id": 4,
        "title": "RecruitLadder",
        "subtitle": "Used by job recruiters to find and manage talent",
        "image": require("./images/search-results.jpg"),
        "alttag": "Search screen listing job seekers",
        "url": "https://uxfol.io/project/04567999/RecruitLadder",
        "tags": []
    }
  ]
}

//console.log(module.exports);